/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Flex, Link as Anchor} from 'rebass'
import { Layout } from '../layouts/Layout'
import { H1, Text} from '../components/Text'

const IndexPage = ({ ...rest }) => {
  return (
    <Layout title="WHOOPS!">
      <Flex flexDirection="column" alignItems="center" my={6}>
        <H1 sx={{marginBottom : 3}}>404!</H1>
        <Text>Looks like you got lost and ended up here instead.</Text>
        <Text>click <Anchor href="/">this link</Anchor> to go back to the homepage.</Text>
      </Flex>
    </Layout>
  );
};

export default IndexPage;
