/** @jsx jsx */
import { jsx } from 'theme-ui';

import MainLayout from './MainLayout';
import { Container } from '../components/Container';
import { H2 } from '../components/Text';
import { Box } from 'rebass';

export const Layout: React.FC<any> = ({ title, children }) => (
  <MainLayout>
    <Container>
      <H2 sx={{ mb: 3 }} id="content">
        {title}
      </H2>
      <Box
        sx={{
          my: [3, 4],
          borderBottom: 'solid 1px',
          borderColor: 'text',
        }}
      />
    </Container>
    <Container>{children}</Container>
  </MainLayout>
);
